<template>
  <div class="grid">
    <div class="col-12 lg:col-6 lg:col-offset-1" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Mailed</span>
            <div class="text-900 font-medium text-xl" v-if="campaign.mailed">
              {{ $filters.numberThousandSeparator(campaign.mailed.total) }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[0] }"
          >
            <i class="pi pi-send text-white text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium"></span
        ><span class="text-500"
          ><small>{{ daysBackLabel }}</small></span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6" :class="xlColSize" v-if="hasDelivered">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Delivered</span>
            <div class="text-900 font-medium text-xl" v-if="campaign.delivered">
              {{ $filters.numberThousandSeparator(campaign.delivered.total) }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[1] }"
          >
            <i class="pi pi-home text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500">
          {{ $filters.numberFourDecimal(deliveredPercentage) }}%</span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Total Responded</span>
            <div class="text-900 font-medium text-xl" v-if="campaign.web_leads">
              {{
                $filters.numberThousandSeparator(
                  campaign.web_leads.total +
                    campaign.generic_web_leads.total +
                    campaign.generic_calls.total_unique +
                    campaign.calls.total_unique
                )
              }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[0] }"
          >
            <i class="pi pi-users text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500"
          >{{
            $filters.numberFourDecimal(
              ((campaign.web_leads.total +
                campaign.generic_web_leads.total +
                campaign.generic_calls.total_unique +
                campaign.calls.total_unique) /
                this.denominator) *
                100
            )
          }}%</span
        >
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Unique Answered Calls</span>
            <div
              class="text-900 font-medium text-xl"
              v-if="campaign.generic_calls || campaign.calls"
            >
              {{
                $filters.numberThousandSeparator(
                  campaign.generic_calls.total + campaign.calls.total
                )
              }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[2] }"
          >
            <i class="pi pi-phone text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500"
          >{{
            $filters.numberFourDecimal(
              ((campaign.generic_calls.total + campaign.calls.total) /
                this.denominator) *
                100
            )
          }}%</span
        >
        <span class="text-500" style="float: right"
          >{{ campaign.calls.duration.average }} m/avg</span
        >
      </div>
    </div>

    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Unique Callers</span>
            <div
              class="text-900 font-medium text-xl"
              v-if="campaign.generic_calls"
            >
              {{
                $filters.numberThousandSeparator(
                  campaign.generic_calls.total_unique +
                    campaign.calls.total_unique
                )
              }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[4] }"
          >
            <i class="pi pi-phone text-white text-xl"></i>
          </div>
        </div>
        <span class="text-500"
          >{{
            $filters.numberFourDecimal(
              ((campaign.generic_calls.total_unique +
                campaign.calls.total_unique) /
                this.denominator) *
                100
            )
          }}%</span
        >
        <span class="text-500" style="float: right"
          >{{ campaign.generic_calls.total_unique }} Generic</span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Web Visits</span>
            <div
              class="text-900 font-medium text-xl"
              v-if="campaign.web_visits"
            >
              {{
                $filters.numberThousandSeparator(
                  campaign.web_visits.total + campaign.generic_web_leads.total
                )
              }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[3] }"
          >
            <i class="pi pi-user-plus text-white text-xl"></i>
          </div>
        </div>

        <span class="text-500"
          >{{
            $filters.numberFourDecimal(
              ((campaign.web_visits.total + campaign.generic_web_leads.total) /
                this.denominator) *
                100
            )
          }}%</span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6" :class="xlColSize">
      <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Web Leads</span>
            <div class="text-900 font-medium text-xl" v-if="campaign.web_leads">
              {{
                $filters.numberThousandSeparator(
                  campaign.web_leads.total + campaign.generic_web_leads.total
                )
              }}
            </div>
          </div>
          <div
            v-if="colorScheme.length > 0"
            class="flex align-items-center justify-content-center border-round"
            style="width: 2.5rem; height: 2.5rem"
            :style="{ 'background-color': colorScheme[3] }"
          >
            <i class="pi pi-user-plus text-white text-xl"></i>
          </div>
        </div>

        <span class="text-500"
          >{{
            $filters.numberFourDecimal(
              ((campaign.web_leads.total + campaign.generic_web_leads.total) /
                this.denominator) *
                100
            )
          }}%</span
        >

        <span
          class="text-500 text-right"
          style="float: right; margin-top: -33px; line-height: 8px"
        >
          <p>
            {{
              $filters.numberTwoDecimal(
                ((campaign.generic_web_leads.total + campaign.web_leads.total) /
                  (campaign.web_visits.total +
                    campaign.generic_web_leads.total)) *
                  100
              )
            }}% CR
          </p>
          {{ campaign.generic_web_leads.total }} Generic</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from "@/stores/app";
export default {
  name: "CampaignStatsFull",
  props: {
    campaign: {
      type: Object,
      default: null,
    },
    daysBack: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return { colorScheme: this.appStore.colorScheme };
  },
  computed: {
    daysBackLabel() {
      if (this.daysBack == null) {
        return "Last 90 Days";
      } else if (Number.isInteger(this.daysBack)) {
        return "Last " + this.daysBack + " days";
      } else {
        return this.daysBack;
      }
    },
    deliveredPercentage() {
      return !this.campaign
        ? 0
        : (this.campaign.delivered.total * 100) / this.campaign.mailed.total;
    },
    responsePercentage() {
      return !this.campaign
        ? 0
        : (this.campaign.web_visits.total * 100) / this.denominator;
    },
    leadPercentage() {
      return !this.campaign
        ? 0
        : (this.campaign.web_leads.total * 100) /
            this.campaign.web_visits.total;
    },
    hasDelivered() {
      return (
        this.campaign &&
        this.campaign.delivered &&
        this.campaign.delivered.total > 0
      );
    },
    deliveredResponsePercentage() {
      return !this.hasDelivered
        ? ""
        : (this.campaign.web_visits.total * 100) /
            this.campaign.delivered.total;
    },
    xlColSize() {
      return this.hasDelivered ? "xl:col-3" : "xl:col-3";
    },
    denominator() {
      if (this.hasDelivered) {
        return this.campaign.delivered.total;
      } else {
        return this.campaign.mailed.total;
      }
    },
  },
};
</script>
